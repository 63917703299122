import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import logo_closed from './logo_closed.png';

import styled from "styled-components";
import { Grid } from '@mui/material';
import './App.css';
import dexscreener from './dexscreener.png';
import x from './x.png';
import telegram from './telegram.png';


import './App.css';

const StyledIcon = styled.img`
  margin-top: 20px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */

  /* Define hover effect */
  &:hover {
    transform: scale(1.5); /* Increase size by 50% */
  }
`

const StyledP = styled.p`
  color: black;

    @media (max-width: 768px) {
font-size: 12px;
}
`


// Styled component for the image
const BlinkingImage = styled.img`
  margin-top: 40px;

`;

const BlinkingComponent = () => {
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBlink(prevBlink => !prevBlink);
    }, 1000); // Change 1000 to adjust blink speed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {/* Render the appropriate image based on state */}
      {blink ? <BlinkingImage src={logo} alt="Image 1" /> : <BlinkingImage src={logo_closed} alt="Image 2" />}
    </div>
  );
};



function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Grid container direction="row" spacing={5} justifyContent={"center"} alignItems={"center"}>
          <Grid item>
              <StyledIcon src={telegram} onClick={() => window.open("https://t.me/paeprSOL", "_blank")}></StyledIcon>
            </Grid>

            <Grid item>
              <StyledIcon src={dexscreener} onClick={() => window.open("https://dexscreener.com/solana/73pyyudgy6v53zpqsfjy4k9dxleruqkbq1vumhkfingr", "_blank")}></StyledIcon>
            </Grid>
            <Grid item>
              <StyledIcon src={x} onClick={() => window.open("https://x.com/paeprsol", "_blank")}></StyledIcon>
            </Grid>
          </Grid>


        <BlinkingComponent />

        <StyledP>
        6rreY2GbZ698mxt1VMyaYwqXLcumXX1eS451joiQpump
        </StyledP>

      </header>

    </div>
  );
}

export default App;
